<script setup>
import { onMounted, ref } from 'vue';
import {useForm } from '@inertiajs/vue3';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';

const form = useForm({
  email: null,
  firstname: null,
  lastname: null,
  hubspotutk:null,
  country:"",
  form_id: '012e8afe-4d8b-4176-af94-c40599289383'
});

const props = defineProps({
  markets: Object,
})

function getCookie(name) {
    let cookieArray = document.cookie.split(';');
    for(let i = 0; i < cookieArray.length; i++) {
        let cookiePair = cookieArray[i].split('=');
        if (name == cookiePair[0].trim()) {
            return cookiePair[1];
        }
    }
    return null;
}

const submit = () => {
  
  form.hubspotutk = getCookie('hubspotutk');
  form.post(route('contact.store'), {
    onFinish: () => location.href = "/CIMethod-Proven-Principles.pdf",
  });

  let modal = document.getElementById('whitepaper-modal');
  modal.style.visibility = 'hidden';
};

function abbreviate(number, maxPlaces, forcePlaces, forceLetter) {
  number = Number(number)
  forceLetter = forceLetter || false
  if(forceLetter !== false) {
    return annotate(number, maxPlaces, forcePlaces, forceLetter)
  }
  var abbr
  if(number >= 1e12) {
    abbr = 'T'
  }
  else if(number >= 1e9) {
    abbr = 'B'
  }
  else if(number >= 1e6) {
    abbr = 'M'
  }
  else if(number >= 1e3) {
    abbr = 'K'
  }
  else {
    abbr = ''
  }
  return annotate(number, maxPlaces, forcePlaces, abbr)
}

function annotate(number, maxPlaces, forcePlaces, abbr) {
  // set places to false to not round
  var rounded = 0
  switch(abbr) {
    case 'T':
      rounded = number / 1e12
      break
    case 'B':
      rounded = number / 1e9
      break
    case 'M':
      rounded = number / 1e6
      break
    case 'K':
      rounded = number / 1e3
      break
    case '':
      rounded = number
      break
  }
  if(maxPlaces !== false) {
    var test = new RegExp('\\.\\d{' + (maxPlaces + 1) + ',}$')
    if(test.test(('' + rounded))) {
      rounded = rounded.toFixed(maxPlaces)
    }
  }
  if(forcePlaces !== false) {
    rounded = Number(rounded).toFixed(forcePlaces)
  }
  return rounded + abbr
}


function updateSelectColor() {

  const selectElement = document.getElementById('country');
  if (selectElement.value === "") {
      selectElement.classList.add('text-gray-400');
      selectElement.classList.remove('text-black');
  } else {
      selectElement.classList.add('text-black');
      selectElement.classList.remove('text-gray-400');
  }
}

const countries = ref([]);
const isModalOpen = ref(false);
function openModal() {
  isModalOpen.value = true;
  loadCountries(); // Load countries when the modal is opened
}

function loadCountries() {
  axios.get('/countries')
    .then(response => {
        countries.value = response.data;
    })
    .catch(error => {
        console.error('Error fetching market companies:', error);
    });
};

</script>
<style>
.splide__arrow {background-color:rgba(177,183,189,1)}
.splide__arrow:hover {background-color:rgba(75,188,133,1)}

.splide__arrow svg {fill:white}
.splide__arrow--prev {left:1rem}
.splide__arrow--next {right:1rem}
@media (min-width: 1024px) {
    .splide__arrow--prev {left:-4rem}
    .splide__arrow--next {right:-4rem}
}
.splide__pagination {bottom:-2rem}
.splide__pagination__page.is-active {background-color:#15293A}
.splide__pagination__page {background-color:rgba(200,200,200,1)}
</style>
<template>

    <section class="mx-auto mt-[180px] md:mt-[112px] lg:mt-[64px]">
        <div class="relative sm:pt-12 lg:pt-16 sm:overflow-hidden bg-gradient-to-b from-white to-accent">
            <div class="relative p-8 sm:pt-12 lg:pt-16 mx-auto max-w-4xl flex flex-col justify-center">
                <h1 class="text-3xl sm:text-5xl lg:text-7xl leading-tight text-left text-black pb-6 font-extrabold -mt-16">
                    <span class="text-primary">Proven wealth building for serious investors</span>
                </h1>
                <h3 class="font-semibold text-xl sm:text-2xl lg:text-4xl leading-tight text-left text-charcoal mb-4">
                    Achieve Warren Buffett-like financial returns and guarantee your future lifestyle
                </h3>
                <p class="font-normal text-lg sm:text-xl lg:text-2xl leading-tight text-left text-charcoal">
                    Discover which stocks genuinely pass the CI Method filters and why.
                </p>
            </div>
            <div class="lg:mt-12 max-w-5xl mx-8 lg:mx-auto lg:-mb-5">
                <div class="h-full w-full object-cover rounded-t-xl lg:shadow-2xl overflow-hidden">
                  <video autoplay playsinline loop class="relative top-[-2px]">
                    <source src="https://d1ie2p3xegzed5.cloudfront.net/ci/cimethod-filter-full-01.mp4" type="video/mp4">
                  </video>
                </div>
            </div>
        </div>
    </section>

    <section class="isolate px-6 lg:pt-24 lg:px-6 mb-12 lg:mb-24 mx-auto mt-16 lg:mt-0 max-w-7xl">
        <div class="relative mx-auto bg-white">
            <div class="relative lg:pb-16 max-w-4xl mx-auto">
                <h2 class="pb-6 font-bold text-3xl sm:text-4xl lg:text-5xl">
                    <span class="text-primary">A more predictable way to invest</span>
                </h2>
                <div class="items-start lg:flex lg:gap-24">
                    <div class="mb-8 text-base sm:text-lg lg:text-xl text-left lg:flex-auto lg:w-full lg:mb-0">
                        <p class="mb-6 leading-normal">
                            Our first step of our process involves filtering all listed companies and only selecting those with outstanding financials, those that would impress Warren Buffett himself. Our approach is one of exclusion - we reject over 95% of companies and only those that pass move onto our Research stage and move closer to being selected as potential "Wealth Winners<sup>®</sup>." Members build a portfolio of wealth winners designed to deliver a total average annual compound return of 15-20%, which equals 400-600% every decade without leverage.
                        </p>
                        <p class="mb-6 leading-normal">
                            Our members are investors who manage their own portfolios and make their own investment decisions. Simply put, the CI Method isn't for short-term traders - it's the ideal choice for investors who want to achieve Buffett-like returns over the long term by harnessing low-risk compounders.
                        </p>                        
                    </div>
                </div>
            </div>
            <div class="relative px-4 mx-auto rounded-lg sm:px-6 lg:px-8 max-w-5xl overflow-hidden sm:rounded-2xl bg-secondary-500">
                <div class="relative py-8 mx-auto">
                    <div class="items-center justify-center text-left lg:flex">
                        <div class="w-[200px] mx-auto hidden lg:block">
                            <img loading="lazy" class="h-full w-full object-cover overflow-hidden -mb-16" src="@images/headshot-buffet.png" alt="Step 1: Filter" />
                        </div>
                        <div class="flex-1 text-center lg:text-left">
                            <h2 class="text-2xl sm:text-3xl lg:text-4xl font-bold text-white">
                                Sound like you?
                            </h2>
                            <p class="flex-1 mt-1 mb-4 text-xl sm:text-2xl lg:text-3xl text-white leading-tight">
                                Take our 5 min Buffett Quiz to see how you measure up?
                            </p>
                        </div>
                        <div class="text-center lg:text-left mb-8 lg:mb-0">
                            <a href="https://quiz.cimethod.com/" target="_blank" class="inline-block p-3 text-2xl font-bold bg-white border border-transparent rounded-md shadow-sm whitespace-nowrap text-secondary-500 hover:bg-black hover:text-white sm:px-8">Take the quiz</a>
                        </div>
                        <div class="w-[200px] mx-auto block lg:hidden">
                            <img loading="lazy" class="h-full w-full object-cover overflow-hidden -mb-12" src="@images/headshot-buffet.png" alt="Step 1: Filter" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
  <section class="mx-auto">
    <div class="relative sm:overflow-hidden bg-gradient-to-t from-charcoal-dark to-charcoal-light">
      <div class="relative p-8 mx-auto sm:py-12 lg:py-24 max-w-7xl">
        <h2 class="pb-6 font-bold text-3xl sm:text-4xl lg:text-5xl max-w-4xl mx-auto text-center">
            <span class="text-white">Access to over 40 markets</span>
        </h2>
        <div class="items-center lg:flex lg:gap-24">
          <div class="mb-2 text-center lg:flex-auto sm:mb-8 lg:mb-0 lg:w-3/4">
            <div class="relative hidden sm:block">
              <img loading="lazy" class="object-cover w-full h-full my-16 rounded-lg sm:rounded-2xl" src="@images/map.svg" alt="" />
              <!-- US -->
              <a v-if="markets['US']" href="/markets/us" class="absolute flex flex-col items-center justify-start flex-grow px-3 py-2 text-center bg-white rounded-lg map-us">
                <div class="flex items-center justify-start gap-2 pb-1 mb-1">
                  <img loading="lazy" class="object-cover w-6 h-6 rounded-full" src="@images/components/flags/1x1/US.svg" alt="" />
                  <h2 class="font-extrabold sm:text-sm lg:text-lg">United States</h2>
                </div>
                <div class="flex items-center w-full gap-2 pt-1 border-t border-gray border-opacity-20">
                  <div class="flex items-center justify-center flex-grow w-1/2 gap-1 border-r border-gray border-opacity-20">
                    <div class="px-1">
                      <h3 class="-mb-1 font-extrabold sm:text-sm lg:text-lg text-primary">{{abbreviate(markets['US']['passes'], 1, false, false)}}</h3>
                      <h5 class="text-xs font-medium text-gray-900 uppercase">Pass</h5>
                    </div>
                  </div>
                  <div class="flex items-center justify-center flex-grow w-1/2 gap-1">
                    <div class="px-1">
                      <h3 class="-mb-1 font-extrabold sm:text-sm lg:text-lg text-danger">{{abbreviate(markets['US']['fails'], 1, false, false)}}</h3>
                      <h5 class="text-xs font-medium text-gray-900 uppercase">Fail</h5>
                    </div>
                  </div>
                </div>
              </a>
              <!-- Canada -->
              <a v-if="markets['CAN']" href="/markets/can" class="absolute flex flex-col items-center justify-start flex-grow px-3 py-2 text-center bg-white rounded-lg map-ca">
                <div class="flex items-center justify-start gap-2 pb-1 mb-1">
                  <img loading="lazy" class="object-cover w-6 h-6 rounded-full" src="@images/components/flags/1x1/CA.svg" alt="" />
                  <h2 class="font-extrabold sm:text-sm lg:text-lg">Canada</h2>
                </div>
                <div class="flex items-center w-full gap-2 pt-1 border-t border-gray border-opacity-20">
                  <div class="flex items-center justify-center flex-grow w-1/2 gap-1 border-r border-gray border-opacity-20">
                    <div class="px-1">
                      <h3 class="-mb-1 font-extrabold sm:text-sm lg:text-lg text-primary">{{abbreviate(markets['CAN']['passes'], 1, false, false)}}</h3>
                      <h5 class="text-xs font-medium text-gray-900 uppercase">Pass</h5>
                    </div>
                  </div>
                  <div class="flex items-center justify-center flex-grow w-1/2 gap-1">
                    <div class="px-1">
                      <h3 class="-mb-1 font-extrabold sm:text-sm lg:text-lg text-danger">{{abbreviate(markets['CAN']['fails'], 1, false, false)}}</h3>
                      <h5 class="text-xs font-medium text-gray-900 uppercase">Fail</h5>
                    </div>
                  </div>
                </div>
              </a>
              <!-- Mexico -->
              <a v-if="markets['MX']" href="/markets/mx" class="absolute flex flex-col items-center justify-start flex-grow px-3 py-2 text-center bg-white rounded-lg map-mx">
                <div class="flex items-center justify-start gap-2 pb-1 mb-1">
                  <img loading="lazy" class="object-cover w-6 h-6 rounded-full" src="@images/components/flags/1x1/MX.svg" alt="" />
                  <h2 class="font-extrabold sm:text-sm lg:text-lg">Mexico</h2>
                </div>
                <div class="flex items-center w-full gap-2 pt-1 border-t border-gray border-opacity-20">
                  <div class="flex items-center justify-center flex-grow w-1/2 gap-1 border-r border-gray border-opacity-20">
                    <div class="px-1">
                      <h3 class="-mb-1 font-extrabold sm:text-sm lg:text-lg text-primary">{{abbreviate(markets['MX']['passes'], 1, false, false)}}</h3>
                      <h5 class="text-xs font-medium text-gray-900 uppercase">Pass</h5>
                    </div>
                  </div>
                  <div class="flex items-center justify-center flex-grow w-1/2 gap-1">
                    <div class="px-1">
                      <h3 class="-mb-1 font-extrabold sm:text-sm lg:text-lg text-danger">{{abbreviate(markets['MX']['fails'], 1, false, false)}}</h3>
                      <h5 class="text-xs font-medium text-gray-900 uppercase">Fail</h5>
                    </div>
                  </div>
                </div>
              </a>
              <!-- Brazil -->
              <a v-if="markets['BR']" href="/markets/br" class="absolute flex flex-col items-center justify-start flex-grow px-3 py-2 text-center bg-white rounded-lg map-br">
                <div class="flex items-center justify-start gap-2 pb-1 mb-1">
                  <img loading="lazy" class="object-cover w-6 h-6 rounded-full" src="@images/components/flags/1x1/BR.svg" alt="" />
                  <h2 class="font-extrabold sm:text-sm lg:text-lg">Brazil</h2>
                </div>
                <div class="flex items-center w-full gap-2 pt-1 border-t border-gray border-opacity-20">
                  <div class="flex items-center justify-center flex-grow w-1/2 gap-1 border-r border-gray border-opacity-20">
                    <div class="px-1">
                      <h3 class="-mb-1 font-extrabold sm:text-sm lg:text-lg text-primary">{{abbreviate(markets['BR']['passes'], 1, false, false)}}</h3>
                      <h5 class="text-xs font-medium text-gray-900 uppercase">Pass</h5>
                    </div>
                  </div>
                  <div class="flex items-center justify-center flex-grow w-1/2 gap-1">
                    <div class="px-1">
                      <h3 class="-mb-1 font-extrabold sm:text-sm lg:text-lg text-danger">{{abbreviate(markets['BR']['fails'], 1, false, false)}}</h3>
                      <h5 class="text-xs font-medium text-gray-900 uppercase">Fail</h5>
                    </div>
                  </div>
                </div>
              </a>
              <!-- UK -->
              <a v-if="markets['UK']" href="/markets/uk" class="absolute flex flex-col items-center justify-start flex-grow px-3 py-2 text-center bg-white rounded-lg map-uk">
                <div class="flex items-center justify-start gap-2 pb-1 mb-1">
                  <img loading="lazy" class="object-cover w-6 h-6 rounded-full" src="@images/components/flags/1x1/UK.svg" alt="" />
                  <h2 class="font-extrabold sm:text-sm lg:text-lg">United Kingdom</h2>
                </div>
                <div class="flex items-center w-full gap-2 pt-1 border-t border-gray border-opacity-20">
                  <div class="flex items-center justify-center flex-grow w-1/2 gap-1 border-r border-gray border-opacity-20">
                    <div class="px-1">
                      <h3 class="-mb-1 font-extrabold sm:text-sm lg:text-lg text-primary">{{abbreviate(markets['UK']['passes'], 1, false, false)}}</h3>
                      <h5 class="text-xs font-medium text-gray-900 uppercase">Pass</h5>
                    </div>
                  </div>
                  <div class="flex items-center justify-center flex-grow w-1/2 gap-1">
                    <div class="px-1">
                      <h3 class="-mb-1 font-extrabold sm:text-sm lg:text-lg text-danger">{{abbreviate(markets['UK']['fails'], 1, false, false)}}</h3>
                      <h5 class="text-xs font-medium text-gray-900 uppercase">Fail</h5>
                    </div>
                  </div>
                </div>
              </a>
              <!-- South Africa -->
              <a v-if="markets['ZA']" href="/markets/za" class="absolute flex flex-col items-center justify-start flex-grow px-3 py-2 text-center bg-white rounded-lg map-za">
                <div class="flex items-center justify-start gap-2 pb-1 mb-1">
                  <img loading="lazy" class="object-cover w-6 h-6 rounded-full" src="@images/components/flags/1x1/ZA.svg" alt="" />
                  <h2 class="font-extrabold sm:text-sm lg:text-lg">South Africa</h2>
                </div>
                <div class="flex items-center w-full gap-2 pt-1 border-t border-gray border-opacity-20">
                  <div class="flex items-center justify-center flex-grow w-1/2 gap-1 border-r border-gray border-opacity-20">
                    <div class="px-1">
                      <h3 class="-mb-1 font-extrabold sm:text-sm lg:text-lg text-primary">{{abbreviate(markets['ZA']['passes'], 1, false, false)}}</h3>
                      <h5 class="text-xs font-medium text-gray-900 uppercase">Pass</h5>
                    </div>
                  </div>
                  <div class="flex items-center justify-center flex-grow w-1/2 gap-1">
                    <div class="px-1">
                      <h3 class="-mb-1 font-extrabold sm:text-sm lg:text-lg text-danger">{{abbreviate(markets['ZA']['fails'], 1, false, false)}}</h3>
                      <h5 class="text-xs font-medium text-gray-900 uppercase">Fail</h5>
                    </div>
                  </div>
                </div>
              </a>
              <!-- China -->
              <a v-if="markets['CN']" href="/markets/cn" class="absolute flex flex-col items-center justify-start flex-grow px-3 py-2 text-center bg-white rounded-lg map-cn">
                <div class="flex items-center justify-start gap-2 pb-1 mb-1">
                  <img loading="lazy" class="object-cover w-6 h-6 rounded-full" src="@images/components/flags/1x1/CN.svg" alt="" />
                  <h2 class="font-extrabold sm:text-sm lg:text-lg">China</h2>
                </div>
                <div class="flex items-center w-full gap-2 pt-1 border-t border-gray border-opacity-20">
                  <div class="flex items-center justify-center flex-grow w-1/2 gap-1 border-r border-gray border-opacity-20">
                    <div class="px-1">
                      <h3 class="-mb-1 font-extrabold sm:text-sm lg:text-lg text-primary">{{abbreviate(markets['CN']['passes'], 1, false, false)}}</h3>
                      <h5 class="text-xs font-medium text-gray-900 uppercase">Pass</h5>
                    </div>
                  </div>
                  <div class="flex items-center justify-center flex-grow w-1/2 gap-1">
                    <div class="px-1">
                      <h3 class="-mb-1 font-extrabold sm:text-sm lg:text-lg text-danger">{{abbreviate(markets['CN']['fails'], 1, false, false)}}</h3>
                      <h5 class="text-xs font-medium text-gray-900 uppercase">Fail</h5>
                    </div>
                  </div>
                </div>
              </a>
              <!-- India -->
              <a v-if="markets['IN']" href="/markets/in" class="absolute flex flex-col items-center justify-start flex-grow px-3 py-2 text-center bg-white rounded-lg map-in">
                <div class="flex items-center justify-start gap-2 pb-1 mb-1">
                  <img loading="lazy" class="object-cover w-6 h-6 rounded-full" src="@images/components/flags/1x1/IN.svg" alt="" />
                  <h2 class="font-extrabold sm:text-sm lg:text-lg">India</h2>
                </div>
                <div class="flex items-center w-full gap-2 pt-1 border-t border-gray border-opacity-20">
                  <div class="flex items-center justify-center flex-grow w-1/2 gap-1 border-r border-gray border-opacity-20">
                    <div class="px-1">
                      <h3 class="-mb-1 font-extrabold sm:text-sm lg:text-lg text-primary">{{abbreviate(markets['IN']['passes'], 1, false, false)}}</h3>
                      <h5 class="text-xs font-medium text-gray-900 uppercase">Pass</h5>
                    </div>
                  </div>
                  <div class="flex items-center justify-center flex-grow w-1/2 gap-1">
                    <div class="px-1">
                      <h3 class="-mb-1 font-extrabold sm:text-sm lg:text-lg text-danger">{{abbreviate(markets['IN']['fails'], 1, false, false)}}</h3>
                      <h5 class="text-xs font-medium text-gray-900 uppercase">Fail</h5>
                    </div>
                  </div>
                </div>
              </a>
              <!-- Hong Kong -->
              <a v-if="markets['HK']" href="/markets/hk" class="absolute flex flex-col items-center justify-start flex-grow px-3 py-2 text-center bg-white rounded-lg map-hk">
                <div class="flex items-center justify-start gap-2 pb-1 mb-1">
                  <img loading="lazy" class="object-cover w-6 h-6 rounded-full" src="@images/components/flags/1x1/HK.svg" alt="" />
                  <h2 class="font-extrabold sm:text-sm lg:text-lg">Hong Kong</h2>
                </div>
                <div class="flex items-center w-full gap-2 pt-1 border-t border-gray border-opacity-20">
                  <div class="flex items-center justify-center flex-grow w-1/2 gap-1 border-r border-gray border-opacity-20">
                    <div class="px-1">
                      <h3 class="-mb-1 font-extrabold sm:text-sm lg:text-lg text-primary">{{abbreviate(markets['HK']['passes'], 1, false, false)}}</h3>
                      <h5 class="text-xs font-medium text-gray-900 uppercase">Pass</h5>
                    </div>
                  </div>
                  <div class="flex items-center justify-center flex-grow w-1/2 gap-1">
                    <div class="px-1">
                      <h3 class="-mb-1 font-extrabold sm:text-sm lg:text-lg text-danger">{{abbreviate(markets['HK']['fails'], 1, false, false)}}</h3>
                      <h5 class="text-xs font-medium text-gray-900 uppercase">Fail</h5>
                    </div>
                  </div>
                </div>
              </a>
              <!-- Australia -->
              <a v-if="markets['AUS']" href="/markets/aus" class="absolute flex flex-col items-center justify-start flex-grow px-3 py-2 text-center bg-white rounded-lg map-au">
                <div class="flex items-center justify-start gap-2 pb-1 mb-1">
                  <img loading="lazy" class="object-cover w-6 h-6 rounded-full" src="@images/components/flags/1x1/AU.svg" alt="" />
                  <h2 class="font-extrabold sm:text-sm lg:text-lg">Australia</h2>
                </div>
                <div class="flex items-center w-full gap-2 pt-1 border-t border-gray border-opacity-20">
                  <div class="flex items-center justify-center flex-grow w-1/2 gap-1 border-r border-gray border-opacity-20">
                    <div class="px-1">
                      <h3 class="-mb-1 font-extrabold sm:text-sm lg:text-lg text-primary">{{abbreviate(markets['AUS']['passes'], 1, false, false)}}</h3>
                      <h5 class="text-xs font-medium text-gray-900 uppercase">Pass</h5>
                    </div>
                  </div>
                  <div class="flex items-center justify-center flex-grow w-1/2 gap-1">
                    <div class="px-1">
                      <h3 class="-mb-1 font-extrabold sm:text-sm lg:text-lg text-danger">{{abbreviate(markets['AUS']['fails'], 1, false, false)}}</h3>
                      <h5 class="text-xs font-medium text-gray-900 uppercase">Fail</h5>
                    </div>
                  </div>
                </div>
              </a>
              <a href="/markets" class="inline-block px-5 py-3 -mt-12 text-xl font-bold text-center border rounded-lg text-primary border-primary mb-9 hover:bg-primary hover:text-white">View all markets</a>
            </div>
          </div>
        </div>        
        <div class="block -mt-2 text-center sm:hidden">
            <ul class="mb-6">
                <li class="border-b border-white/10">
                    <a v-if="markets['AUS']" href="/markets/aus" class="flex items-center justify-start gap-2 p-3 hover:bg-black">   
                        <div class="flex items-center justify-start gap-2 grow">
                            <img loading="lazy" class="object-cover w-6 h-6 rounded-full" src="@images/components/flags/1x1/AU.svg" alt="Flag" />
                            <h2 class="text-base font-extrabold text-white">Australia</h2>
                        </div>
                        <div class="flex items-center w-[150px]">
                            <div class="flex items-center justify-center flex-grow w-1/2 border-r border-gray border-opacity-20">
                                <div class="px-3">
                                    <h3 class="-mb-1 text-xl font-extrabold leading-tight text-primary">{{abbreviate(markets['AUS']['passes'], 1, false, false)}}</h3>
                                    <h5 class="text-xs font-medium text-gray-900 uppercase">Pass</h5>
                                </div>
                            </div>
                            <div class="flex items-center justify-center flex-grow w-1/2">
                                <div class="px-3">
                                    <h3 class="-mb-1 text-xl font-extrabold leading-tight text-danger">{{abbreviate(markets['AUS']['fails'], 1, false, false)}}</h3>
                                    <h5 class="text-xs font-medium text-gray-900 uppercase">Fail</h5>
                                </div>
                            </div>
                        </div>
                    </a>
                </li>
                <li class="border-b border-white/10">
                    <a v-if="markets['BR']" href="/markets/br" class="flex items-center justify-start gap-2 p-3 hover:bg-black">   
                        <div class="flex items-center justify-start gap-2 grow">
                            <img loading="lazy" class="object-cover w-6 h-6 rounded-full" src="@images/components/flags/1x1/BR.svg" alt="Flag" />
                            <h2 class="text-base font-extrabold text-white">Brazil</h2>
                        </div>
                        <div class="flex items-center w-[150px]">
                            <div class="flex items-center justify-center flex-grow w-1/2 border-r border-gray border-opacity-20">
                                <div class="px-3">
                                    <h3 class="-mb-1 text-xl font-extrabold leading-tight text-primary">{{abbreviate(markets['BR']['passes'], 1, false, false)}}</h3>
                                    <h5 class="text-xs font-medium text-gray-900 uppercase">Pass</h5>
                                </div>
                            </div>
                            <div class="flex items-center justify-center flex-grow w-1/2">
                                <div class="px-3">
                                    <h3 class="-mb-1 text-xl font-extrabold leading-tight text-danger">{{abbreviate(markets['BR']['fails'], 1, false, false)}}</h3>
                                    <h5 class="text-xs font-medium text-gray-900 uppercase">Fail</h5>
                                </div>
                            </div>
                        </div>
                    </a>
                </li>
                <li class="border-b border-white/10">
                    <a v-if="markets['CAN']" href="/markets/can" class="flex items-center justify-start gap-2 p-3 hover:bg-black">   
                        <div class="flex items-center justify-start gap-2 grow">
                            <img loading="lazy" class="object-cover w-6 h-6 rounded-full" src="@images/components/flags/1x1/CA.svg" alt="Flag" />
                            <h2 class="text-base font-extrabold text-white">Canada</h2>
                        </div>
                        <div class="flex items-center w-[150px]">
                            <div class="flex items-center justify-center flex-grow w-1/2 border-r border-gray border-opacity-20">
                                <div class="px-3">
                                    <h3 class="-mb-1 text-xl font-extrabold leading-tight text-primary">{{abbreviate(markets['CAN']['passes'], 1, false, false)}}</h3>
                                    <h5 class="text-xs font-medium text-gray-900 uppercase">Pass</h5>
                                </div>
                            </div>
                            <div class="flex items-center justify-center flex-grow w-1/2">
                                <div class="px-3">
                                    <h3 class="-mb-1 text-xl font-extrabold leading-tight text-danger">{{abbreviate(markets['CAN']['fails'], 1, false, false)}}</h3>
                                    <h5 class="text-xs font-medium text-gray-900 uppercase">Fail</h5>
                                </div>
                            </div>
                        </div>
                    </a>
                </li>
                <li class="border-b border-white/10">
                    <a v-if="markets['CN']" href="/markets/aus" class="flex items-center justify-start gap-2 p-3 hover:bg-black">   
                        <div class="flex items-center justify-start gap-2 grow">
                            <img loading="lazy" class="object-cover w-6 h-6 rounded-full" src="@images/components/flags/1x1/CN.svg" alt="Flag" />
                            <h2 class="text-base font-extrabold text-white">China</h2>
                        </div>
                        <div class="flex items-center w-[150px]">
                            <div class="flex items-center justify-center flex-grow w-1/2 border-r border-gray border-opacity-20">
                                <div class="px-3">
                                    <h3 class="-mb-1 text-xl font-extrabold leading-tight text-primary">{{abbreviate(markets['CN']['passes'], 1, false, false)}}</h3>
                                    <h5 class="text-xs font-medium text-gray-900 uppercase">Pass</h5>
                                </div>
                            </div>
                            <div class="flex items-center justify-center flex-grow w-1/2">
                                <div class="px-3">
                                    <h3 class="-mb-1 text-xl font-extrabold leading-tight text-danger">{{abbreviate(markets['CN']['fails'], 1, false, false)}}</h3>
                                    <h5 class="text-xs font-medium text-gray-900 uppercase">Fail</h5>
                                </div>
                            </div>
                        </div>
                    </a>
                </li>
                <li class="border-b border-white/10">
                    <a v-if="markets['HK']" href="/markets/hk" class="flex items-center justify-start gap-2 p-3 hover:bg-black">   
                        <div class="flex items-center justify-start gap-2 grow">
                            <img loading="lazy" class="object-cover w-6 h-6 rounded-full" src="@images/components/flags/1x1/HK.svg" alt="Flag" />
                            <h2 class="text-base font-extrabold text-white">Hong Kong</h2>
                        </div>
                        <div class="flex items-center w-[150px]">
                            <div class="flex items-center justify-center flex-grow w-1/2 border-r border-gray border-opacity-20">
                                <div class="px-3">
                                    <h3 class="-mb-1 text-xl font-extrabold leading-tight text-primary">{{abbreviate(markets['HK']['passes'], 1, false, false)}}</h3>
                                    <h5 class="text-xs font-medium text-gray-900 uppercase">Pass</h5>
                                </div>
                            </div>
                            <div class="flex items-center justify-center flex-grow w-1/2">
                                <div class="px-3">
                                    <h3 class="-mb-1 text-xl font-extrabold leading-tight text-danger">{{abbreviate(markets['HK']['fails'], 1, false, false)}}</h3>
                                    <h5 class="text-xs font-medium text-gray-900 uppercase">Fail</h5>
                                </div>
                            </div>
                        </div>
                    </a>
                </li>
                <li class="border-b border-white/10">
                    <a v-if="markets['IN']" href="/markets/in" class="flex items-center justify-start gap-2 p-3 hover:bg-black">   
                        <div class="flex items-center justify-start gap-2 grow">
                            <img loading="lazy" class="object-cover w-6 h-6 rounded-full" src="@images/components/flags/1x1/AU.svg" alt="Flag" />
                            <h2 class="text-base font-extrabold text-white">India</h2>
                        </div>
                        <div class="flex items-center w-[150px]">
                            <div class="flex items-center justify-center flex-grow w-1/2 border-r border-gray border-opacity-20">
                                <div class="px-3">
                                    <h3 class="-mb-1 text-xl font-extrabold leading-tight text-primary">{{abbreviate(markets['IN']['passes'], 1, false, false)}}</h3>
                                    <h5 class="text-xs font-medium text-gray-900 uppercase">Pass</h5>
                                </div>
                            </div>
                            <div class="flex items-center justify-center flex-grow w-1/2">
                                <div class="px-3">
                                    <h3 class="-mb-1 text-xl font-extrabold leading-tight text-danger">{{abbreviate(markets['IN']['fails'], 1, false, false)}}</h3>
                                    <h5 class="text-xs font-medium text-gray-900 uppercase">Fail</h5>
                                </div>
                            </div>
                        </div>
                    </a>
                </li>
                <li class="border-b border-white/10">
                    <a v-if="markets['MX']" href="/markets/mx" class="flex items-center justify-start gap-2 p-3 hover:bg-black">   
                        <div class="flex items-center justify-start gap-2 grow">
                            <img loading="lazy" class="object-cover w-6 h-6 rounded-full" src="@images/components/flags/1x1/MX.svg" alt="Flag" />
                            <h2 class="text-base font-extrabold text-white">Mexico</h2>
                        </div>
                        <div class="flex items-center w-[150px]">
                            <div class="flex items-center justify-center flex-grow w-1/2 border-r border-gray border-opacity-20">
                                <div class="px-3">
                                    <h3 class="-mb-1 text-xl font-extrabold leading-tight text-primary">{{abbreviate(markets['MX']['passes'], 1, false, false)}}</h3>
                                    <h5 class="text-xs font-medium text-gray-900 uppercase">Pass</h5>
                                </div>
                            </div>
                            <div class="flex items-center justify-center flex-grow w-1/2">
                                <div class="px-3">
                                    <h3 class="-mb-1 text-xl font-extrabold leading-tight text-danger">{{abbreviate(markets['MX']['fails'], 1, false, false)}}</h3>
                                    <h5 class="text-xs font-medium text-gray-900 uppercase">Fail</h5>
                                </div>
                            </div>
                        </div>
                    </a>
                </li>
                <li class="border-b border-white/10">
                    <a v-if="markets['ZA']" href="/markets/za" class="flex items-center justify-start gap-2 p-3 hover:bg-black">   
                        <div class="flex items-center justify-start gap-2 grow">
                            <img loading="lazy" class="object-cover w-6 h-6 rounded-full" src="@images/components/flags/1x1/ZA.svg" alt="Flag" />
                            <h2 class="text-base font-extrabold text-white">South Africa</h2>
                        </div>
                        <div class="flex items-center w-[150px]">
                            <div class="flex items-center justify-center flex-grow w-1/2 border-r border-gray border-opacity-20">
                                <div class="px-3">
                                    <h3 class="-mb-1 text-xl font-extrabold leading-tight text-primary">{{abbreviate(markets['ZA']['passes'], 1, false, false)}}</h3>
                                    <h5 class="text-xs font-medium text-gray-900 uppercase">Pass</h5>
                                </div>
                            </div>
                            <div class="flex items-center justify-center flex-grow w-1/2">
                                <div class="px-3">
                                    <h3 class="-mb-1 text-xl font-extrabold leading-tight text-danger">{{abbreviate(markets['ZA']['fails'], 1, false, false)}}</h3>
                                    <h5 class="text-xs font-medium text-gray-900 uppercase">Fail</h5>
                                </div>
                            </div>
                        </div>
                    </a>
                </li>
                <li class="border-b border-white/10">
                    <a v-if="markets['UK']" href="/markets/uk" class="flex items-center justify-start gap-2 p-3 hover:bg-black">   
                        <div class="flex items-center justify-start gap-2 grow">
                            <img loading="lazy" class="object-cover w-6 h-6 rounded-full" src="@images/components/flags/1x1/UK.svg" alt="Flag" />
                            <h2 class="text-base font-extrabold text-white">United Kingdom</h2>
                        </div>
                        <div class="flex items-center w-[150px]">
                            <div class="flex items-center justify-center flex-grow w-1/2 border-r border-gray border-opacity-20">
                                <div class="px-3">
                                    <h3 class="-mb-1 text-xl font-extrabold leading-tight text-primary">{{abbreviate(markets['UK']['passes'], 1, false, false)}}</h3>
                                    <h5 class="text-xs font-medium text-gray-900 uppercase">Pass</h5>
                                </div>
                            </div>
                            <div class="flex items-center justify-center flex-grow w-1/2">
                                <div class="px-3">
                                    <h3 class="-mb-1 text-xl font-extrabold leading-tight text-danger">{{abbreviate(markets['UK']['fails'], 1, false, false)}}</h3>
                                    <h5 class="text-xs font-medium text-gray-900 uppercase">Fail</h5>
                                </div>
                            </div>
                        </div>
                    </a>
                </li>
                <li class="border-b border-white/10">
                    <a v-if="markets['US']" href="/markets/us" class="flex items-center justify-start gap-2 p-3 hover:bg-black">   
                        <div class="flex items-center justify-start gap-2 grow">
                            <img loading="lazy" class="object-cover w-6 h-6 rounded-full" src="@images/components/flags/1x1/US.svg" alt="Flag" />
                            <h2 class="text-base font-extrabold text-white">United States</h2>
                        </div>
                        <div class="flex items-center w-[150px]">
                            <div class="flex items-center justify-center flex-grow w-1/2 border-r border-gray border-opacity-20">
                                <div class="px-3">
                                    <h3 class="-mb-1 text-xl font-extrabold leading-tight text-primary">{{abbreviate(markets['US']['passes'], 1, false, false)}}</h3>
                                    <h5 class="text-xs font-medium text-gray-900 uppercase">Pass</h5>
                                </div>
                            </div>
                            <div class="flex items-center justify-center flex-grow w-1/2">
                                <div class="px-3">
                                    <h3 class="-mb-1 text-xl font-extrabold leading-tight text-danger">{{abbreviate(markets['US']['fails'], 1, false, false)}}</h3>
                                    <h5 class="text-xs font-medium text-gray-900 uppercase">Fail</h5>
                                </div>
                            </div>
                        </div>
                    </a>
                </li>
            </ul>
            <a href="/markets" class="inline-block px-5 py-3 mx-auto text-xl font-bold text-center align-middle border rounded-lg text-primary border-primary mb-9 hover:bg-primary hover:text-white">View all markets</a>
        </div>            
      </div>
    </div>
  </section>
 
  <section class="py-8 sm:pt-16 lg:pt-24 bg-accent overflow-hidden">
    <div class="mx-auto max-w-5xl">
        <h2 class="mb-8 text-3xl font-bold sm:text-4xl lg:text-5xl">
            What makes CI Method different?
        </h2>    
        <div class="mb-8 text-base sm:text-lg lg:text-xl text-left lg:flex-auto lg:w-full lg:mb-0">
            <p class="mb-6 leading-normal">
                CI Method is more than an investment platform. It's more than a methodology. Through exclusive membership, we've cultivated a community of successful business leaders, professionals and investors with a combined knowledge of over 10,000 years of experience for each member to draw on. 
            </p>
            <p class="mb-6 leading-normal">
                Our data gives us insights. Our community adds power to those insights and makes them hyper-relevant. We encourage members to collaborate at the membership level they feel most comfortable with. Whether you thirst for wealth management knowledge from more tenured investors or are keen to contribute research, you can shape your CI Method journey to get the best results.
            </p>
            <p class="mb-6 leading-normal">
                Our unique SMaRT workshops make up a crucial part of assessing which companies - therefore stocks - qualify as Wealth Winners®, significantly increasing your opportunity to achieve Warren Buffett-like compounded returns.
            </p>
        </div>
    </div>    
    <!-- div class="px-6 mx-auto lg:flex lg:gap-12 max-w-6xl">
      <div class="relative mb-4 overflow-hidden lg:w-1/3 sm:mb-8 lg:mb-12">
        <div class="p-4 sm:p-6 lg:p-8">
            <img class="w-full rounded-xl mb-8" src="@images/feature-02.png" alt="Icon" />
            <h2 class="text-2xl font-bold sm:text-2xl lg:text-2xl text-primary">
                Analysis
            </h2>
            <p class="text-lg lg:text-xl">
                Daily analysis and scoring of 49,000+ companies across 44 markets.
            </p>
        </div>
      </div>
      <div class="relative mb-4 overflow-hidden lg:w-1/3 sm:mb-8 lg:mb-12">
        <div class="p-4 sm:p-6 lg:p-8">
            <img class="w-full rounded-xl mb-8" src="@images/feature-01.png" alt="Icon" />
            <h2 class="text-2xl font-bold sm:text-2xl lg:text-2xl text-primary">
                Lifelong Wealth
            </h2>
            <p class="text-lg lg:text-xl">
                Patient investment that leads to long term wealth.
            </p>
        </div>
      </div>
      <div class="relative mb-4 overflow-hidden lg:w-1/3 sm:mb-8 lg:mb-12">
        <div class="p-4 sm:p-6 lg:p-8">
            <img class="w-full rounded-xl mb-8" src="@images/feature-04.png" alt="Icon" />
            <h2 class="text-2xl font-bold sm:text-2xl lg:text-2xl text-primary">
                Process
            </h2>
            <p class="text-lg lg:text-xl">
                A unique process of elimination to identify Wealth Winners<sup>®</sup>.
            </p>
        </div>
      </div>
    </div -->
    <div class="text-center">
      <a href="/what-is-cimethod" class="inline-block p-3 mt-6 text-2xl font-bold text-white border border-transparent rounded-md shadow-sm bg-primary hover:bg-black hover:text-white sm:px-8">Find out more</a>
    </div>
    <div class="lg:mt-12 max-w-5xl mx-8 lg:mx-auto lg:-mb-12">
        <img loading="lazy" class="h-full w-full object-cover rounded-t-xl shadow-2xl overflow-hidden" src="@images/screenshot-research.png" alt="" />        
    </div>
  </section>
  <section class="pt-8 sm:pt-16 lg:pt-24 bg-white">
    <div class="relative mx-auto rounded-lg max-w-6xl sm:rounded-2xl">
      <div class="relative mx-auto max-w-7xl">
        <h2 class="text-3xl sm:text-3xl lg:text-5xl font-bold text-center text-black">
            <span class="">What Our Members Say</span>
        </h2>
        <Splide :options="{ rewind: true }" aria-label="My Favorite Images">
            <SplideSlide>
                <div class="px-16 lg:px-0 pt-16 mx-auto lg:flex lg:gap-8 max-w-6xl">
                    <div class="p-8 mb-8 text-left lg:flex-auto lg:w-1/3 lg:mb-0 lg:text-left rounded-xl bg-gradient-to-t from-charcoal-dark to-charcoal-light">
                        <blockquote class="mb-4 text-lg text-white sm:text-xl">&ldquo;It's the best investing decision Ive ever made. I just feel much more confident and love coming to the MasterClasses as I learn so much.&rdquo;</blockquote>
                        <div class="mt-2">
                            <div class="flex items-center justify-start">
                                <div class="flex-shrink-0">
                                    <img loading="lazy" class="w-16 h-16 mx-auto mr-3 rounded-full" src="@images/components/headshot-dforbes.png" alt="" />
                                </div>
                                <div class="flex flex-col text-left">
                                <div class="text-base font-medium text-gray-900">Deborah Forbes</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-8 mb-8 text-left lg:flex-auto lg:w-1/3 lg:mb-0 lg:text-left rounded-xl bg-gradient-to-t from-charcoal-dark to-charcoal-light">
                        <blockquote class="mb-4 text-lg text-white sm:text-xl">&ldquo;When the going got tough, my broker let me down badly and I realised I needed to do something more to manage my money better.&rdquo;</blockquote>
                        <div class="mt-2">
                            <div class="flex items-center justify-start">
                                <div class="flex-shrink-0">
                                    <img loading="lazy" class="w-16 h-16 mx-auto mr-3 rounded-full" src="@images/components/headshot-gmontague.png" alt="" />
                                </div>
                                <div class="flex flex-col text-left">
                                <div class="text-base font-medium text-gray-900">Greg Montague</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-8 mb-8 text-left lg:flex-auto lg:w-1/3 lg:mb-0 lg:text-left rounded-xl bg-gradient-to-t from-charcoal-dark to-charcoal-light">
                        <blockquote class="mb-4 text-lg text-white sm:text-xl">&ldquo;I joined CI Method to surround myself with like-minded people. it's a structured environment with companies analysed in a very detailed way.&rdquo;</blockquote>
                        <div class="mt-2">
                            <div class="flex items-center justify-start">
                                <div class="flex-shrink-0">
                                    <img loading="lazy" class="w-16 h-16 mx-auto mr-3 rounded-full" src="@images/components/headshot-qking.png" alt="" />
                                </div>
                                <div class="flex flex-col text-left">
                                <div class="text-base font-medium text-gray-900">Quinn King</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SplideSlide>
            <SplideSlide>
                <div class="px-4 lg:px-0 lg:pt-16 mx-auto lg:flex lg:gap-16 max-w-6xl">
                    <div class="p-8 mb-8 text-left lg:flex-auto lg:w-1/3 lg:mb-0 lg:text-left rounded-xl bg-gradient-to-t from-charcoal-dark to-charcoal-light">
                        <blockquote class="mb-4 text-lg text-white sm:text-xl">&ldquo;The CI Method software is phenomenally good at filtering out the companies we don't want invest in.&rdquo;</blockquote>
                        <div class="mt-2">
                            <div class="flex items-center justify-start">
                                <div class="flex-shrink-0">
                                    <img loading="lazy" class="w-16 h-16 mx-auto mr-3 rounded-full" src="@images/components/headshot-jrosenberg.png" alt="" />
                                </div>
                                <div class="flex flex-col text-left">
                                <div class="text-base font-medium text-gray-900">James Rosenberg</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-8 mb-8 text-left lg:flex-auto lg:w-1/3 lg:mb-0 lg:text-left rounded-xl bg-gradient-to-t from-charcoal-dark to-charcoal-light">
                        <blockquote class="mb-4 text-lg text-white sm:text-xl">&ldquo;I'm very comfortable with the Buffet approach to long term investing, of understanding both the philosophy and the business.&rdquo;</blockquote>
                        <div class="mt-2">
                            <div class="flex items-center justify-start">
                                <div class="flex-shrink-0">
                                    <img loading="lazy" class="w-16 h-16 mx-auto mr-3 rounded-full" src="@images/components/headshot-pfenech.png" alt="" />
                                </div>
                                <div class="flex flex-col text-left">
                                <div class="text-base font-medium text-gray-900">Pierre Fenech</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-8 mb-8 text-left lg:flex-auto lg:w-1/3 lg:mb-0 lg:text-left rounded-xl bg-gradient-to-t from-charcoal-dark to-charcoal-light">
                        <blockquote class="mb-4 text-lg text-white sm:text-xl">&ldquo;The experience and business acumen in the room at every single meeting at CI Method is absolutely incredible.&rdquo;</blockquote>
                        <div class="mt-2">
                            <div class="flex items-center justify-start">
                                <div class="flex-shrink-0">
                                    <img loading="lazy" class="w-16 h-16 mx-auto mr-3 rounded-full" src="@images/components/headshot-pbell.png" alt="" />
                                </div>
                                <div class="flex flex-col text-left">
                                <div class="text-base font-medium text-gray-900">Peter Bell</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SplideSlide>
        </Splide>
        
      </div>
    </div>
  </section>

  <Splide
      aria-labelledby="autoplay-example-heading"
      :options="options"
      :has-track="false"
    >
      <div style="position: relative">
        <SplideTrack>
          <SplideSlide v-for="slide in slides" :key="slide.alt">
            <div class="px-4 lg:px-0 lg:pt-16 mx-auto lg:flex lg:gap-16 max-w-7xl">
            <div class="p-8 mb-8 text-left lg:flex-auto lg:w-1/3 lg:mb-0 lg:text-left rounded-xl bg-gradient-to-t from-charcoal-dark to-charcoal-light">
                <blockquote class="mb-4 text-lg text-white sm:text-xl">&ldquo;The CI Method software is phenomenally good at filtering out the companies we don't want invest in.&rdquo;</blockquote>
                <div class="mt-2">
                    <div class="flex items-center justify-start">
                        <div class="flex-shrink-0">
                            <img loading="lazy" class="w-16 h-16 mx-auto mr-3 rounded-full" src="@images/components/headshot-jrosenberg.png" alt="" />
                        </div>
                        <div class="flex flex-col text-left">
                        <div class="text-base font-medium text-gray-900">James Rosenberg</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-8 mb-8 text-left lg:flex-auto lg:w-1/3 lg:mb-0 lg:text-left rounded-xl bg-gradient-to-t from-charcoal-dark to-charcoal-light">
                <blockquote class="mb-4 text-lg text-white sm:text-xl">&ldquo;I'm very comfortable with the Buffet approach to long term investing, of understanding both the philosophy and the business.&rdquo;</blockquote>
                <div class="mt-2">
                    <div class="flex items-center justify-start">
                        <div class="flex-shrink-0">
                            <img loading="lazy" class="w-16 h-16 mx-auto mr-3 rounded-full" src="@images/components/headshot-pfenech.png" alt="" />
                        </div>
                        <div class="flex flex-col text-left">
                        <div class="text-base font-medium text-gray-900">Pierre Fenech</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-8 mb-8 text-left lg:flex-auto lg:w-1/3 lg:mb-0 lg:text-left rounded-xl bg-gradient-to-t from-charcoal-dark to-charcoal-light">
                <blockquote class="mb-4 text-lg text-white sm:text-xl">&ldquo;The experience and business acumen in the room at every single meeting at CI Method is absolutely incredible.&rdquo;</blockquote>
                <div class="mt-2">
                    <div class="flex items-center justify-start">
                        <div class="flex-shrink-0">
                            <img loading="lazy" class="w-16 h-16 mx-auto mr-3 rounded-full" src="@images/components/headshot-pbell.png" alt="" />
                        </div>
                        <div class="flex flex-col text-left">
                        <div class="text-base font-medium text-gray-900">Peter Bell</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
          </SplideSlide>
        </SplideTrack>
      </div>

      <div class="splide__progress">
        <div class="splide__progress__bar">
        </div>
      </div>

      <!--
        <button class="splide__toggle">
        <span class="splide__toggle__play">Play</span>
        <span class="splide__toggle__pause">Pause</span>
      </button>
      -->
    </Splide>

  <!-- section class="px-3 mx-auto my-6 sm:my-8 lg:my-12 max-w-7xl lg:px-6">
    <div class="relative px-8 mx-auto bg-white rounded-lg sm:px-16 lg:px-24 max-w-7xl sm:overflow-hidden sm:rounded-2xl">
      <div class="relative py-8 mx-auto sm:py-16 lg:py-24 max-w-7xl">
        <h2 class="mb-6 text-3xl font-bold text-center sm:text-4xl lg:text-5xl">
          Find Out More
        </h2>
        <div class="text-center">
          <p class="mb-4 text-lg text-gray-900 sm:text-xl lg:text-2xl">If you are interested in finding out how CI Method can help you achieve your investing goals, we have an insightful white paper Finding Wealth Winners & Avoiding Capital Killers.</p>
          <a href="#whitepaper-modal" class="inline-block p-3 mt-6 text-2xl font-bold text-white border border-transparent rounded-md shadow-sm bg-primary hover:bg-black hover:text-white sm:px-8">Download the White Paper</a>
        </div>
      </div>
    </div>
  </section -->

  <div class="relative my-16 px-4 mx-4 lg:mx-auto rounded-lg sm:px-6 lg:px-8 max-w-6xl overflow-hidden sm:rounded-2xl bg-secondary-500">
      <div class="relative py-8 mx-auto">
            <div class="items-center justify-center text-left lg:flex">
                <div class="w-[250px] rotate-[1deg] lg:mr-8 lg:pr-4 hidden lg:block">
                    <img loading="lazy" class="h-full w-full object-cover rounded-xl shadow-2xl overflow-hidden -mb-16" src="@images/whitepaper.png" alt="" />
                </div>
                <div class="flex-1 text-center lg:text-left">
                    <h2 class="text-2xl sm:text-3xl lg:text-4xl font-bold text-white">
                        Find Out More
                    </h2>
                    <p class="flex-1 mt-1 mb-4 text-xl sm:text-2xl lg:text-3xl text-white leading-tight">
                        If you are interested in finding out how CI Method can help you achieve your investing goals, we have an insightful white paper Finding Wealth Winners<sup>®</sup> & Avoiding Capital Killers.
                    </p>
                    <a href="#whitepaper-modal" @click="openModal" class="inline-block p-3 mb-8 mt-4 text-2xl font-bold bg-white border border-transparent rounded-md shadow-sm whitespace-nowrap text-secondary-500 hover:bg-black hover:text-white sm:px-8">
                        Download the White Paper
                    </a>
                </div>
                <div class="w-[200px] block lg:hidden mx-auto lg:mx-0">
                    <img loading="lazy" class="h-full w-full object-cover rounded-xl shadow-2xl overflow-hidden -mb-16" src="@images/whitepaper.png" alt="" />
                </div>
            </div>
        </div>
    </div>

  <!-- div class="relative px-4 mx-4 mt-16 lg:mx-auto rounded-lg sm:px-6 lg:px-8 mb-16 max-w-6xl overflow-hidden sm:rounded-2xl bg-primary">
    <div class="relative pt-8 mx-auto">
        <div class="text-left lg:justify-stretch lg:items-end align-bottom items-end lg:flex gap-16">
            <div class="flex-1">
                <h2 class="text-3xl sm:text-3xl lg:text-4xl font-bold text-white">
                    Find Out More
                </h2>
                <p class="flex-1 mb-4 text-lg lg:text-xl text-white">
                    If you are interested in finding out how CI Method can help you achieve your investing goals, we have an insightful white paper Finding Wealth Winners & Avoiding Capital Killers.
                </p>
                <a href="#whitepaper-modal" class="inline-block p-3 mb-8 mt-4 text-2xl font-bold bg-white border border-transparent rounded-md shadow-sm whitespace-nowrap text-primary hover:bg-black hover:text-white sm:px-8">
                    Download the White Paper
                </a>
            </div>  
            <div class="max-w-[200px] mx-auto h-full rotate-[1deg] lg:mr-4 lg:pr-4">
                <img class="h-full w-full object-cover rounded-xl shadow-2xl overflow-hidden -mb-4" src="@images/whitepaper.png" alt="" />
            </div>         
        </div>
    </div>
</div --> 

  <div v-if="isModalOpen" id="whitepaper-modal" class="modal-window">
    <div class="bg-white rounded-lg open-modal-window w-10/12 lg:w-4/6 lg:h-auto my-auto overflow-y-auto flex items-center">
      <div class="relative flex lg:justify-center">
        <div class="hidden lg:block w-1/2 2xl:w-1/3 bg-black p-16">
            <img loading="lazy" class="relative h-full w-full object-cover rounded-xl shadow-2xl overflow-hidden -rotate-[1deg]" src="@images/whitepaper.png" alt="" />
        </div>        
        <div class="lg:w-1/2 2xl:w-2/3 p-6 flex justify-center items-center">
            <div class="">
                <h5 class="mb-3 text-2xl font-semibold leading-6 text-primary">Download the White Paper</h5>
                <p class="text-lg">Register your interest to join our community of serious investors committed to long-term wealth building and identify "Weather Winners®" with a proven, systematic approach to investing, delivering an average annual total compound return of 15-20% without leverage.</p>   
                <form @submit.prevent="submit" class="mt-5">
                    <div class="flex items-center gap-2 p-1 mb-3 border border-gray-300 rounded-md focus-within:border-primary">
                      <label for="firstname" class="sr-only">First Name</label>
                      <input v-model="form.firstname" required type="text" name="firstname" id="firstname" class="block w-full p-0 px-2 text-lg border-0 rounded-lg placeholder:text-gray-400 focus:ring-0" placeholder="First name">
                    </div>
                    <div class="flex items-center gap-2 p-1 mb-3 border border-gray-300 rounded-md focus-within:border-primary">
                      <label for="lastname" class="sr-only">Last Name</label>
                      <input v-model="form.lastname" required type="text" name="lastname" id="lastname" class="block w-full p-0 px-2 text-lg border-0 rounded-lg placeholder:text-gray-400 focus:ring-0" placeholder="Last name">
                    </div>
                    <div class="flex items-center gap-2 p-1 mb-3 border border-gray-300 rounded-md focus-within:border-primary">
                      <label for="email" class="sr-only">Email</label>
                      <input v-model="form.email" required type="email" name="email" id="email" class="block w-full p-0 px-2 text-lg border-0 rounded-lg placeholder:text-gray-400 focus:ring-0" placeholder="you@example.com">
                    </div>
                    <div class="flex items-center gap-2 p-1 mb-3 border border-gray-300 rounded-md focus-within:border-primary">
                      <label for="country" class="sr-only">Country</label>
                      <select v-model="form.country" required name="country" id="country" class="block w-full p-0 px-2 text-lg border-0 rounded-lg focus:ring-0 text-gray-400" @change="updateSelectColor">
                        <option value="" disabled selected>Select a country</option>
                        <option v-for="(id, val) in countries" :value="val">{{ val }}</option>
                      </select>

            
                    </div>
                    
                    <button type="submit" class="block w-full px-3 py-2 text-lg font-bold text-white rounded-md bg-primary hover:bg-charcoal">Download</button>
                </form>
                <a href="#" title="Close" class="flex items-center justify-center p-3 py-2 mt-3 text-lg font-bold text-gray-500 bg-white rounded-md hover:bg-black hover:text-white">Cancel</a>
              </div>
            </div>
      </div>      
    </div>
  </div>
</template>
